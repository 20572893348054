.error {
  border: 2px solid red;
}
.errorTxt {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  height: 100%;
}
