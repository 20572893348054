@import "../../css/utils";

.formCantainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 48px;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
}

.field {
  @include field;
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  @media screen and (max-width: $small-mobile) {
    flex-direction: column-reverse;
    gap: 10px;
  }
}

.btn {
  @include button-green;
  width: 192px;
  height: 48px;
}

.btn:hover {
  background-color: #1a671e;
}
