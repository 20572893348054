@import "../../css/colors";
@import "../../css/utils";

.btn {
  @include button-green;
  width: 192px;
  height: 48px;
}

.btn:hover {
  background-color: #1a671e;
}

.btnContainer {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
}
