@import "../../css/utils";

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
}

.formCantainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 48px;
}

.btn {
  @include button-green;
  width: 192px;
  height: 48px;
}

.btn:hover {
  background-color: #1a671e;
}

.field {
  @include field;
}
