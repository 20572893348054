@import "../../../css/colors";
@import "../../../css/utils";

.passwordContainer {
  display: flex;
  justify-content: space-between;
}

.forgottenPassword {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: $th-green-color;
}

.forgottenPassword:hover {
  cursor: pointer;
}

.passwordFieldContainer {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.eyeIconContainer {
  position: absolute;
  margin: 12px;
}

.eyeIconContainer:hover {
  cursor: pointer;
}
