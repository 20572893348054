@import "./colors";

$card-width: 552px;
$card-height: 584px;

$mobile: 550px;
$small-mobile: 450px;

@mixin card {
  height: $card-height;
  width: $card-width;
  border-radius: 16px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  overflow: hidden;
}

@mixin button-green {
  color: #f9f9f9;
  background-color: $th-green-color;
  border-radius: 4px;
  border: 0px;
}

@mixin field {
  height: 48px;
  border-radius: 4px;
  border: solid 1px #b3b8be;
  padding: 12px 0px 12px 16px;

  width: 100%;
}
