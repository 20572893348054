@import "../../../css/utils";

.field {
  @include field;
}

.error {
  margin-top: 4px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #df1a3c;
  min-height: 18px;
  width: 100%;
  text-align: left;
  line-height: 16px;
}

.marginBottom {
  margin-bottom: 40px;
}

.errorFieldBorder {
  border-color: #df1a3c;
}
