@import "./_utils.scss";
@import "./colors";

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  height: 100%;
}

#root {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.card {
  @include card;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.14;
}

button {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
}

@mixin regular-text {
  font-size: 14px;
  line-height: 1.71;
  color: $txt-clr;
  margin-bottom: 8px;
}

p {
  @include regular-text;
}

.title {
  color: $txt-clr;
  padding: 40px 0px 32px;
}

.btn-link {
  color: #138518;
  display: -ms-flexbox;
  display: flex;
  text-decoration: underline;
  background-color: transparent;
  min-width: auto;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
}

.center-txt {
  text-align: center;
}

.error {
  margin-top: 4px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #df1a3c;
  min-height: 18px;
  width: 100%;
  text-align: left;
  line-height: 16px;
  margin-bottom: 30px;
}

.margin-bottom {
  margin-bottom: 40px;
}

.error-field-border {
  border-color: #df1a3c !important;
}
