@import "../../../css/colors";
@import "../../../css/utils";

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  background-color: $card-header-bg-clr;

  img {
    margin: 32px 0px 24px;
  }
}

.layout {
  height: calc(100% - 110px);
  height: -moz-calc(100% - 110px);
  height: -webkit-calc(100% - 110px);
  padding: 0px 64px;

  @media screen and (max-width: $mobile) {
    padding: 0px 42px;
  }

  @media screen and (max-width: $small-mobile) {
    padding: 0px 24px;
  }
}
